<template>
    <CContainer fluid>
        <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast :key="'toast' + toast.text + index" :show='true'
                    :header="toast.header === '' ? null : toast.header">
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
        <CRow>
            <CCol class='col-12'>
                <CCard>
                    <CRow class='mt-3 ml-3 mr-3 justify-content-between'>
                        <CCol class='col-12 col-xl-3 col-md-6 mb-1 justify-content-end'>
                            <CButton @click='showAddModal()' block color='success'>abonelik ekle</CButton>

                        </CCol>
                    </CRow>
                    <CCardHeader>
                        <strong>Abonelik Planları</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol class='col-6'>
                                <CInput label='Arama: ' :horizontal="{ label: 'w-auto', input: 'w-100' }"
                                    v-debounce:600ms='changeFilterValue' />
                            </CCol>
                        </CRow>
                        <CDataTable class='mb-0 table-outline' hover :items='tableItems' :fields='tableFields'
                            head-color='light' :loading='isListLoading' :items-per-page='itemsPerPage'>
                            <template #no-items-view>
                                <CRow>
                                    <CCol class='col-12 font-3xl text-center'>
                                        <CIcon name='cil-x' />
                                        Veri Bulunamadı
                                    </CCol>
                                </CRow>
                            </template>
                            <td slot='id' slot-scope='{item}'>
                                <div>{{ item.id }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='earnedCredits' slot-scope='{item}'>
                                <div>{{ item.earnedCredits }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='name' slot-scope='{item}'>
                                <div>{{ item.name }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='endDate' slot-scope='{item}'>
                                <div>{{ item.endDate }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot="startDate" slot-scope='{item}'>
                                <div>{{ item.startDate }}</div>
                                <div class='small text-muted'>
                                </div>
                            </td>

                            <td slot='manage' slot-scope={item}>
                                <CButton @click='showEditModal(item.id)' color='success' class='mr-2 mb-1'>
                                    <CIcon name='cil-pencil' />
                                </CButton>
                            </td>
                        </CDataTable>
                        <CPagination v-if='totalPages !== 0' :pages='totalPages' :active-page='currentPage'
                            :activePage.sync='currentPage' align='center' class='mt-3'>
                        </CPagination>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>

        <!-- New Subscription Modal -->

        <CModal title='Kredi ekle' color='Info' :show.sync='newCreditModal'>
            <CContainer fluid>
                <CRow>
                    <CCol class='col-12'>
                        <label>haftanın günleri</label>
                        <Select2 v-model="studentMetadataId" :options="studentMetadata" name="haftanın günleri"
                            @change="myChangeEvent($event)" @select="mySelectEvent($event)" />

                        <!-- <CSelect label="haftanın günleri" :value.sync="studentMetadataId" :options="studentMetadata"
                            placeholder="" /> -->
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='kazanılan krediler' type='number' description='ex. kazanılan krediler'
                            label='kazanılan krediler' v-model="earnedCredits">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <!-- <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='kalan krediler' type='number' description='ex. kalan krediler' label='kalan krediler' v-model="remainingCredits">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow> -->
                <!-- <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Harcanan Krediler' type='number' description='ex. Harcanan Krediler' label='Harcanan Krediler' v-model="spentCredits">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow> -->
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Başlangıç ​​tarihi' type='datetime-local' description='ex. Başlangıç ​​tarihi'
                            label='Başlangıç ​​tarihi' v-model="startDate">
                            <!-- <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template> -->
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='bitiş tarihi' type='datetime-local' description='ex. bitiş tarihi'
                            label='bitiş tarihi' v-model="endDate">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='newCreditModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' :disabled='isDisabled' @click='addCredits' :loading='isLoading'>
                                Değişiklikleri
                                Kaydet
                            </CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>

        <!-- Edit Subscription Modal -->

        <CModal title='Kredi ekle' color='Info' :show.sync='editCreditModal'>
            <CContainer fluid>
                <CRow>
                    <CCol class='col-12'>
                        <CSelect label="haftanın günleri" :value.sync="studentMetadataId" :options="studentMetadata"
                            placeholder="" />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='kazanılan krediler' type='number' description='ex. kazanılan krediler'
                            label='kazanılan krediler' v-model="earnedCredits">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='kalan krediler' type='number' description='ex. kalan krediler'
                            label='kalan krediler' v-model="remainingCredits">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Harcanan Krediler' type='number' description='ex. Harcanan Krediler'
                            label='Harcanan Krediler' v-model="spentCredits">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='Başlangıç ​​tarihi' type='datetime-local' description='ex. Başlangıç ​​tarihi'
                            label='Başlangıç ​​tarihi' v-model="startDate">
                            <!-- <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template> -->
                        </CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol class='col-12 mt-1'>
                        <CInput placeholder='bitiş tarihi' type='datetime-local' description='ex. bitiş tarihi'
                            label='bitiş tarihi' v-model="endDate">
                            <template #prepend-content>
                                <CIcon name='cil-font' />
                            </template>
                        </CInput>
                    </CCol>
                </CRow>
            </CContainer>
            <template v-slot:footer>
                <CContainer fluid>
                    <CRow class='d-flex justify-content-between'>
                        <CCol class='col-6'>
                            <CButton @click='editCreditModal = false' block color='dark'>Vazgeç</CButton>
                        </CCol>
                        <CCol class='col-6'>
                            <CButton block color='info' @click='updateCreditModal' :loading='isLoading'>Değişiklikleri
                                Kaydet
                            </CButton>
                        </CCol>
                    </CRow>
                </CContainer>
            </template>
        </CModal>
    </CContainer>
</template>

<script>
import { studentsCredit } from '@/services/api-service'
import { ReferralAdministration } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import moment from 'moment'
import Select2 from 'vue3-select2-component';

export default {
    name: 'StudentCredits',
    components: { Select2 },
    Vcomponents: {
        VueLoadingButton
    },
    mounted() {
        this.loadCreditsList()
        this.loadStudentMetadataIdList()
    },
    watch: {
        filterValue() {
            this.loadCreditsList()
        },
        currentPage() {
            this.loadCreditsList()
        },
    },

    data() {
        return {
            filterValue: '',
            currentPage: 1,
            itemsPerPage: 10,
            totalPages: 1,
            isStyled: true,
            isDisabled: false,
            currentItem: '',
            studentMetadataId: 0,
            id: '',
            earnedCredits: 0,
            name: '',
            studentMetadata: [
                { id: '', text: 'seçme' }
            ],
            spentCredits: 0,
            remainingCredits: 0,
            startDate: '',
            endDate: '',


            isListLoading: false,
            isLoading: false,
            editCreditModal: false,
            newCreditModal: false,
            toasts: [],
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'name', label: 'Isim' },
                { key: 'earnedCredits', label: 'Kazanılan Krediler' },
                { key: 'spentCredits', label: 'Harcanan Krediler' },
                { key: 'remainingCredits', label: 'kalan krediler' },
                { key: 'startDate', label: 'Başlangıç ​​tarihi' },
                { key: 'endDate', label: 'Bitiş tarihi' },
                // { key: 'manage', label: 'üstesinden gelmek' },
            ],
            myValue: '1',
            myOptions: [{ id: '1', text: 'Test1' }, { id: '2', text: 'Test1' }, { id: '3', text: 'Test1' }] // or [{id: key, text: value}, {id: key, text: value}]
        }
    },
    computed: {
    },
    methods: {
        myChangeEvent(val) {
            console.log(val);
        },
        mySelectEvent({ id, text }) {
            console.log({ id, text });
            this.studentMetadataId = id;
        },
        changeFilterValue(newValue) {
            this.filterValue = newValue
        },
        async showAddModal() {
            this.id = '';
            this.earnedCredits = 0;
            this.spentCredits = 0;
            this.remainingCredits = 0;
            this.startDate = '';
            this.endDate = '';
            this.studentMetadataId = 0;
            this.newCreditModal = !this.newCreditModal
        },
        async showEditModal(id) {
            this.currentItem = this.tableItems.find(x => x.id === id)

            this.isListLoading = true;
            const result = await studentsCredit.getDetailsByIdCredits(this.currentItem?.id)
            console.log(result);

            this.id = result.data.id;
            this.earnedCredits = result.data.earnedCredits;
            var startDateFormat = moment(result.data.startDate).format('MM-DD-YYYYThh:mm');
            this.startDate = startDateFormat;
            var endDateFormat = moment(result.data.endDate).format('MM-DD-YYYYThh:mm');
            this.endDate = endDateFormat;
            this.remainingCredits = result.data.remainingCredits;
            this.spentCredits = result.data.spentCredits;
            this.studentMetadataId = result.data.studentMetadataId;

            this.isListLoading = false;
            console.log(this.currentItem)
            this.editCreditModal = !this.editCreditModal
        },
        showAlert() {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },
        showToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        deleteToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        async addCredits() {
            this.isDisabled = true;
            let currentDate = new Date();
            console.log(currentDate.toISOString());
            if (this.startDate >= this.endDate || this.endDate <= currentDate.toISOString()) {
                this.$toast.warning('Bitiş tarihi Başlangıç ​​tarihinden büyük olmalıdır!');
                this.isDisabled = false;
                return
            }
            if (this.earnedCredits && this.startDate && this.studentMetadataId && this.endDate) {

                this.isLoading = true
                try {
                    const result = await studentsCredit.createCredits({
                        earnedCredits: this.earnedCredits,
                        // remainingCredits: this.remainingCredits,
                        // spentCredits: this.spentCredits,
                        startDate: this.startDate,
                        studentMetadataId: this.studentMetadataId,
                        endDate: this.endDate,
                    })
                    this.isDisabled = false;

                    this.loadCreditsList();
                    this.$toast.success('Yeni Abonelik Başarıyla Eklendi!')

                    this.earnedCredits = 0;
                    this.remainingCredits = 0;
                    this.spentCredits = 0;
                    this.startDate = '';
                    this.endDate = '';
                    this.studentMetadataId = 0;

                    this.isLoading = false;
                    this.newCreditModal = false;
                }
                catch (e) {
                    throw new e
                }
            }
        },
        async updateCreditModal() {
            if (this.earnedCredits && this.remainingCredits && this.spentCredits && this.startDate && this.endDate && this.studentMetadataId) {
                this.isLoading = true
                try {
                    this.isListLoading = true;

                    const result = await studentsCredit.updateCredits({
                        id: this.id,
                        earnedCredits: this.earnedCredits,
                        remainingCredits: this.remainingCredits,
                        spentCredits: this.spentCredits,
                        startDate: this.startDate,
                        studentMetadataId: this.studentMetadataId,
                        endDate: this.endDate,
                    })
                    console.log(result);

                    this.loadCreditsList();
                    this.$toast.success('Abonelik Başarıyla Güncellendi!')

                    this.earnedCredits = 0;
                    this.remainingCredits = 0;
                    this.spentCredits = 0;
                    this.startDate = '';
                    this.endDate = '';
                    this.studentMetadataId = 0;

                    this.isLoading = false
                    this.editCreditModal = false;
                }
                catch (e) {
                    throw new e
                }
            }
        },
        async loadCreditsList() {
            this.isListLoading = true;
            const result = await studentsCredit.listStudentCredits(this.currentPage, this.itemsPerPage, this.filterValue)
            console.log(result);

            this.tableItems = result.data.studentCreditList

            console.log(this.tableItems)

            this.totalPages = result.data.pagination.totalPages
            this.currentPage = result.data.pagination.currentPage
            this.itemsPerPage = result.data.pagination.recordsPerPage

            this.isListLoading = false;
        },
        async loadStudentMetadataIdList() {
            const result = await studentsCredit.getDropDownStudents()
            console.log(result);
            if (result.data) {
                result.data.forEach(d => {
                    this.studentMetadata.push({
                        text: d.value,
                        id: d.id
                    })
                });
            }
        },
    },
}
</script>
